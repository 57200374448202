<template>
  <v-main>
    <PagePopUp
      v-model="dialog"
      :page="openpage"
      :loading="dialogLoading"
    />
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>Обучение</v-card-title>
      <v-container>
        <v-tabs v-model="tab">
          <v-tab
            v-for="(tab, index) in items"
            :key="index"
            :href="'#tab-' + index"
          >
            {{ tab.title }}
          </v-tab>
          <v-tab-item
            v-for="(tab, index) in items"
            :key="index"
            :value="'tab-' + index"
          >
            <v-card
              flat
              tile
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  order-lg="2"
                >
                  <v-toolbar
                    flat
                    color="white"
                  >
                    <v-btn
                      color="primary"
                      :href="getEducationsBookPDFLink(tab)"
                      :loading="pdfLoading"
                      class="mx-auto"
                      target="_blank"
                    >
                      <v-icon left>
                        mdi-download
                      </v-icon>PDF
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col
                  cols="12"
                  lg="8"
                  order-lg="1"
                >
                  <v-card-text>
                    <v-treeview
                      :items="tab.children"
                      item-text="title"
                      open-on-click
                      activatable
                      :open-all="openTree"
                      :active.sync="active"
                    />
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import educations from "@/api/educations";
import PagePopUp from "@/components/PagePopUp";

export default {
  name: "EducationView",
  components: {
    PagePopUp,
  },
  data: () => ({
    items: [],
    loading: true,
    dialog: false,
    dialogLoading: true,
    openpage: {},
    active: [],
    openTree: false,
    tab: null,
    pdfLoading: false,
  }),
  watch: {
    active(v) {
      if (Array.isArray(v) && v.length && window.isFinite(v[0]))
        this.openDialog(v[0]);
    },
  },
  created() {
    if (this.$route.params.id) this.openDialog(this.$route.params.id);
    educations.getTree().then((response) => {
      this.items = response.data;
      this.loading = false;
    });
  },
  methods: {
    openDialog(id) {
      this.dialog = true;
      this.dialogLoading = true;
      this.active = [];
      educations.getPage(id).then((response) => {
        this.openpage = response.data;
        this.dialogLoading = false;
      });
    },
    getEducationsBookPDFLink({ id }) {
      return `${this.$store.state.baseURL}/educations/page/${id}/pdf/?token=${this.$store.state.auth.token}`;
    },
  },
};
</script>

<style>
.v-slide-group__prev {
  display: none !important;
}
</style>
