import api from '@/api';

const page_path = '/educations/page/';

export default {
  getTree() {
    return new Promise((resolve, reject) => {
      api
      .get(page_path)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
  getPage(id) {
    return new Promise((resolve, reject) => {
      api
      .get(`${page_path}${id}/`)
      .then(response => resolve(response))
      .catch(error => reject(error));
    })
  },
}
